<template>
	<div class="contract-operation-page">
        <a-timeline>
            <a-timeline-item v-for="item in 4">
                <div class="log-timeline-item" >
                    <a-row class="log-time-row">
                        <div class="log-time-icon"><a-icon type="edit" style="color: white;background-color: #108ee9;" /></div><div class="log-time-text">2020-19-18 18:00</div>
                    </a-row>
                    <a-row class="log-content-row" style="">
                        <a-col :span="2">
                            <a-avatar :size="48" icon="user"/>
                        </a-col>
                        <a-col class="log-detail-col" :span="22">
                            <div><span class="log-detail-username">张三</span><span>修改课时</span></div>
                            <div><span>修改赠送课时：</span><span>20 -> 10</span></div>
                            <div><span>延长时间：</span><span>2020-10-10 -> 2020-12-10</span></div>
                            <div><span>修改原因：</span><span>内容内容内容内容内容内容内容 </span></div>
                        </a-col>
                    </a-row>
                </div>
            </a-timeline-item>

        </a-timeline>
	</div>
</template>
<script>
	export default {
		name: 'ContractOptionLog',
		data() {
			return {
                commissionInfoVisible:false,
            }
		},
		created() {
		},
		mounted() {

		},
		methods: {
            commissionInfoShow(){
                this.commissionInfoVisible=true;
            }
		}
	}
</script>
<style scoped lang="less">
	.contract-operation-page {
        padding-top: 50px;
        padding-left: 50px;
        .log-timeline-item {
            .log-time-row{
                .log-time-icon{
                    display: inline-block;
                    border-radius: 5px;
                    box-sizing: border-box;
                    background-color: #108ee9;
                    height: 18px;
                    width: 18px;
                    overflow: hidden;
                }
                .log-time-text{
                    display: inline-block;
                    margin-left: 10px;
                }
            }


            .log-content-row{
                margin-top: 10px;
                border: lightgrey 1px solid;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 16px;
                width: min-content;
                min-width: 500px;
                max-width: 800px;
                .log-detail-col{
                    padding-left: 22px;

                    div{
                        line-height: 24px;

                        .log-detail-username {
                            font-size: 20px;
                            margin-right:16px;
                        }
                    }
                }
            }
        }
        margin-bottom: 120px;
	}



</style>
