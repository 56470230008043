<template>
    <div class="contract-manage-list-page">
        <div class="page-bread-menu flex">
            <span style="font-size: 16px;">合同管理</span>
        </div>
        <a-divider/>
        <div class="page-search-form">
            <a-form layout="inline" :form="searchForm"  @submit="searchSubmit">
                <a-form-item layout="inline" label="合同归属">
                    <a-select default-value="all" v-model="searchForm.contractBelong" style="width: 120px" @change="searchBelongChange">
                        <a-select-option :value="item.value" v-for="(item,index) in contractBelongOptions" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item layout="inline" label="合同状态">
                    <a-select default-value="all"  v-model="searchForm.contractState" style="width: 120px" @change="searchStateChange">
                        <a-select-option :value="item.value" v-for="(item,index) in contractStateOptions" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </div>
        <a-divider/>
        <div>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <a slot="ContractNo" style="color:  #108ee9" slot-scope="row" @click="contractDrawerOpen(row)">{{ row }}111</a>
            </a-table>
        </div>
        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>


        <a-drawer class="contract-detail-drawer"  width="80%" :closable="false" :visible="showContractDetail" height="500"
                  @close="contractDrawClose">
            <a-row class="contract-detail-head-row">
                <a-col  :span="6" class="contract-detail-brief-col contract-head-col-icon">
                    <img src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" alt="">
                </a-col>
                <a-col :span="14" class="contract-detail-brief-col">
                    <p>合同</p>
                    <p>TL-A0123456-基础能力</p>
                </a-col>
                <a-col :span="8" class="contract-drawer-btn-group">
                    <a-button size="large" class="contract-drawer-group-btn">电子合同</a-button>
                    <a-button size="large" class="contract-drawer-group-btn" @click="openModalByKey('showUpdateLessonForm')">调整课次</a-button>
                    <a-button size="large" class="contract-drawer-group-btn" @click="showContractFreezingForm=true">冻结合同</a-button>
                    <a-dropdown size="large" class="contract-drawer-group-btn">
                        <a-menu slot="overlay" @click="handleContrctOperateClick">
                            <a-menu-item key="FeeRefund">发起退费</a-menu-item>
                            <a-menu-item key="2">刷卡记录</a-menu-item>
                            <a-menu-item key="3">合同变更</a-menu-item>
                            <a-menu-item key="ExtendedDate">延长期限</a-menu-item>
                            <a-menu-item key="IssueInvoice">开发票</a-menu-item>
                            <a-menu-item key="SendContractWp">发送电子合同</a-menu-item>
                            <a-menu-item key="AddCommissionUserShow">添加分单人</a-menu-item>
                            <a-menu-item key="DeleteContract">删除合同</a-menu-item>
                        </a-menu>
                        <a-button style="height: 40px;position: relative;top: -2px"> 更多操作
                            <a-icon type="down"/>
                        </a-button>
                    </a-dropdown>
                </a-col>
            </a-row>
            <a-divider></a-divider>
            <a-row class="contract-detail-brief-row">
                <a-col :span="6" class="contract-detail-brief-col">
                    <p>合同状态</p>
                    <p>已开启</p>
                </a-col>
                <a-col :span="6" class="contract-detail-brief-col">
                    <p>学员</p>
                    <p>汪乐</p>
                </a-col>
                <a-col :span="6" class="contract-detail-brief-col">
                    <p>合同金额</p>
                    <p>1000.00</p>
                </a-col>
                <a-col :span="6" class="contract-detail-brief-col">
                    <p>签单时间</p>
                    <p>2022-10-29 08:00:00</p>
                </a-col>
            </a-row>
            <a-divider></a-divider>
            <div class="contract-detail-tab-row">
                <a-tabs default-active-key="contractDetail" @change="contractDrawTabTap">
                    <a-tab-pane key="contractDetail" tab="详细信息" class="contract-detail-tab-menu">
                        <ContactDetail></ContactDetail>
                    </a-tab-pane>
                    <a-tab-pane key="contractTransLog" tab="交易流水" force-render class="contract-detail-tab-menu">
                        <ContactTransLog></ContactTransLog>
                    </a-tab-pane>
                    <a-tab-pane key="contractOperateLog" tab="操作记录" class="contract-detail-tab-menu">
                        <ContactOptionLog></ContactOptionLog>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </a-drawer>

        <!--    冻结的form      -->
        <a-modal
            title="冻结合同"
            :visible="showContractFreezingForm"
            :confirm-loading="contractFreezingLoading"
            :dialog-style="{ right: '-30vw',height:'200%'}"
            @cancel="contractFreezingFormCancel"
            @ok="contractFreezingSubmit"

        >
            <a-row style="background-color: #f0f0f1;height: 60px ;line-height: 60px;margin-bottom: 20px">
                <div style="display: inline-block;width: 45%;text-align: center" >剩余冻结次数 :2次</div>
                <div style="display: inline-block;width: 45%;text-align: center"> 剩余冻结时长: 60天</div>
            </a-row>
            <a-row>
                <a-form-model :form="contractFreezingForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="contractFreezingSubmit">
                    <a-form-model-item label="冻结开始日期" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-date-picker style="width: 350px"
                                       v-model="contractFreezingForm.startDate"
                                       format="YYYY-MM-DD"
                                       placeholder="冻结开始日期"
                        />
                    </a-form-model-item>
                    <a-form-model-item  label="冻结结束日期" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-date-picker style="width:  350px"
                                       v-model="contractFreezingForm.endDate"
                                       format="YYYY-MM-DD"
                                       placeholder="选择冻结结束日期"
                        />
                    </a-form-model-item >
                    <a-form-model-item  label="冻结原因" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-textarea placeholder="Basic usage" :rows="4" v-model="contractFreezingForm.remark" />
                    </a-form-model-item>
                </a-form-model>
            </a-row>
        </a-modal>



        <a-modal
            title="添加分单人"
            :visible="showAddCommissionUserForm"
            :dialog-style="{ right: '-30vw',height:'200%'}"
            :confirm-loading="addCommissionUserLoading"
            @cancel="addCommissionUserFormCancel"
        >
            <a-form-model :form="addCommissionUserForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="contractFreezingSubmit">
                <a-form-model-item  label="合同编号" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input placeholder="合同编号" v-model="operationContractItem.ContractNo" :disabled="true"/>
                </a-form-model-item>
                <a-form-model-item  label="分单人中心" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-tree-select
                        v-model="addCommissionUserForm.centerId"
                        show-search
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        placeholder="请选择分单人中心"
                        allow-clear
                        tree-default-expand-all
                    >
                        <a-tree-select-node  v-for="(topItem,topIndex) in userCenters" :key="topIndex" :value="topItem.centerID" :title="topItem.centerName" :disabled="topItem.disabled">
                            <a-tree-select-node v-for="(secItem,secIndex) in topItem.children" :key="secIndex" :value="secItem.centerID" :title="secItem.centerName" :disabled="secItem.disabled">
                                <a-tree-select-node v-for="(thirdItem,thirdIndex) in secItem.children" :key="thirdIndex" :value="thirdItem.centerID" :title="thirdItem.centerName" :disabled="thirdItem.disabled">

                                </a-tree-select-node>
                            </a-tree-select-node>
                        </a-tree-select-node>
                    </a-tree-select>
                </a-form-model-item>
                <a-form-model-item  label="选择分单人" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-select placeholder="选择分单人" @change="commissionUserCenterChange" >
                        <a-select-option v-model="item.Name" v-for="item in addCommissionUsers" :key="item.userId">
                            {{ item.Name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item  label="应付金额" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input placeholder="输入应付金额" v-model="operationContractItem.Amount"/>
                </a-form-model-item>
                <a-form-model-item  label="选择分单比例" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-select default-value="10" v-model="addCommissionUserForm.commissionPercent" loading>
                        <a-select-option value="lucy">
                            10%
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>

        </a-modal>



        <a-modal
            title="开发票"
            :visible="showModalObject.showIssueInvoiceForm"
            :confirm-loading="addCommissionUserLoading"
            @cancel="closeModalByKey('showIssueInvoiceForm')"
            @ok="issueInvoiceSubmit"
        >
            <a-form-model :form="issueInvoiceForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="issueInvoiceSubmit">
                <a-form-model-item  label="发票号" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-input placeholder="输入发票号" v-model="issueInvoiceForm.invoiceNo"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>



        <a-modal
            title="删除合同"
            :visible="showModalObject.showDeleteContractForm"
            :confirm-loading="addCommissionUserLoading"
            @cancel="closeModalByKey('showDeleteContractForm')"
            @ok="deleteContractSubmit"
        >
           <a-row style="padding: 30px 0 ">
             <p style=" text-align: center;font-size: 16px">    确认删除合同:{{operationContractItem.ContractNo}}?</p>
           </a-row>
        </a-modal>



        <a-modal
            title="延长期限"
            :visible="showModalObject.showExtendedDateForm"
            :confirm-loading="addCommissionUserLoading"
            @cancel="closeModalByKey('showExtendedDateForm')"
            @ok="extendedDateSubmit"
        >
            <a-form-model :form="extendedDateForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="extendedDateSubmit">
                <a-form-model-item  label="结束日期" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-date-picker style="width:  350px"
                                   v-model="extendedDateForm.extendedDate"
                                   format="YYYY-MM-DD"
                                   placeholder="选择结束日期"
                    />
                </a-form-model-item >
                <a-form-model-item  label="修改原因" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-textarea placeholder="请填写修改原因" :rows="4" v-model="extendedDateForm.remark" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>


        <a-modal
            title="公众号发送电子合同"
            :visible="showModalObject.showSendContractWpForm"
            @cancel="closeModalByKey('showSendContractWpForm')"
            @ok="closeModalByKey('showSendContractWpForm')"
        >
            <a-row v-if="!operationContractItem.customWpUsers && operationContractItem.customWpUsers.length<=0">
                <a-col :span="2">
                    <a-icon type="exclamation-circle" style="color: #ffbb94" theme="filled" />
                </a-col>
                <a-col :span="20">
                    <p> 客户没有关注公众号，你可以引导客户先关注公众号</p>
                </a-col>
            </a-row>
            <a-row v-else v-for="(user,index) in operationContractItem.customWpUsers" style=" height: 100px;line-height: 100px" :key="index">
                <a-col :span="4">
                    <a-avatar :size="64" icon="user" />
                </a-col>
                <a-col :span="4">
                    <p>{{ user.username }}</p>
                </a-col>
                <a-col :span="16" style="text-align: center">
                    <a-button type="primary" @click="sendContractWpMsg(item,index)"> 发送</a-button>
                </a-col>
            </a-row>
        </a-modal>


        <a-modal
            title="修改课时"
            :visible="showModalObject.showUpdateLessonForm"
            :dialog-style="{ right: '-30vw',height:'200%'}"
            @cancel="closeModalByKey('showUpdateLessonForm')"
            @ok="updateLessonSubmit"

        >
            <a-row>
                <a-form-model :form="updateLessonForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="updateLessonSubmit">
                    <a-form-model-item label="修改课时类型" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择修改类型" default-value=""  v-model="updateLessonForm.updateLessonType" >
                            <a-select-option value="null" >未选择</a-select-option>
                            <a-select-option value="basic" >基本课时剩余课时</a-select-option>
                            <a-select-option value="giving" >赠送课时剩余课时</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item  label="修改类型" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择修改类型" default-value="" v-model="updateLessonForm.addType" >
                            <a-select-option value="null" >未选择</a-select-option>
                            <a-select-option value="add" >增加</a-select-option>
                            <a-select-option value="reduce" >减少</a-select-option>
                        </a-select>
                    </a-form-model-item >
                    <a-form-model-item  label="修改原因" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-textarea placeholder="请输入修改原因" :rows="4" v-model="contractFreezingForm.remark" />
                    </a-form-model-item>
                </a-form-model>
            </a-row>
        </a-modal>


    </div>
</template>
<script>
import ContactDetail from "./ContactDetail";
import ContactOptionLog from "./ContactOptionLog";
import ContactTransLog from "./ContactTransLog";
export default {
    name: 'ContactManageList',
    components: {
        ContactDetail,
        ContactOptionLog,
        ContactTransLog
    },
    data() {
        return {
            loading:false,
            contractFreezingLoading:false,
            addCommissionUserLoading:false,

            showContractDetail:true,
            showContractFreezingForm:false,
            showAddCommissionUserForm:false,



            showModalObject:{
                showIssueInvoiceForm:false,  //开发票
                showDeleteContractForm:false , //删除合同
                showExtendedDateForm:false , //删除合同
                showSendContractWpForm:false,  //发送电子合同
                showUpdateLessonForm:false,  //修改课时
            },


            searchForm:{
                contractBelong:"all",
                contractState:null,
            },

            contractFreezingForm:{
                startDate:"",
                endDate:"",
                remark:'',
            },

            addCommissionUserForm:{
                startDate:"",
                endDate:"",
                centerId:'',
                commissionPercent:0.00,
            },

            issueInvoiceForm:{
                contractNo:"ws156651546",
                invoiceNo:"",

            },

            extendedDateForm:{
                contractNo:"ws156651546",
                extendedDate:null,
                remark:""
            },


            updateLessonForm:{
                contractNo:"ws156651546",
                addType:'',  // add :+  | reduce: -
                updateLessonType:'',  //basic 基本课时剩余课时  | giving  赠送课时剩余课时
                remark:""
            },




            operationContractItem:{
                ContractNo:"ws156651546",
                CommissionUsers:[
                    {
                        username:"张三",
                        commissionAmount:2536.02,
                        commissionPercent: 20,
                        belongToCenter:"深圳印力中心"
                    }
                ],

                customWpUsers: [
                    {
                        username: "李莉莉1",
                        avatar: "",
                        toUserId: "",
                        loading:"",
                        sendSuccess:false,
                    },
                    {
                        username: "李莉莉2",
                        avatar: "",
                        toUserId: "",
                        loading:"",
                        sendSuccess:false,
                    },
                ]

            },  //当前弹出的、编辑中的合同


            userCenters: [
                {
                    centerID: 'jiangsu',
                    centerName: '华南区',
                    disabled:true,
                    children: [
                        {
                            centerID: 'nanjing',
                            centerName: '广东',
                            disabled:true,
                            children: [
                                {
                                    centerID: 'zhonghuamen',
                                    centerName: '深圳七田真',
                                    disabled:false,
                                },
                            ],
                        }
                    ]
                },
            ],
            addCommissionUsers:[
                {
                  Name:'Mr 张',
                  UserId :"df4e4fg45g4rd5fsafef58fe8e6"
                },
            ],

            contractBelongOptions: [
                {
                    label: '全部',
                    value:"all"
                },
                {
                    label: '我负责的',
                    value:"my"
                },
                {
                    label: '下属负责的',
                    value:"my"
                }
            ],
            contractStateOptions: [
                {
                    label: '全部',
                    value:"all"
                },
                {
                    label: '待审批',
                    value:"1"
                },
                {
                    label: '已开启',
                    value:"2"
                },
                {
                    label: '已完结',
                    value:"3"
                },
                {
                    label: '冻结中',
                    value:"-1"
                }
            ],
            columns : [
                {
                    title: '合同编号',
                    dataIndex: 'ContractNo',
                    sorter: true,
                    width: 'auto',
                    scopedSlots: { customRender: 'ContractNo' },
                },
                {
                    title: '客户',
                    dataIndex: 'CustomName',
                    width: 'auto',
                },
                {
                    title: '收费标准',
                    dataIndex: 'PayStandard',
                },
                {
                    title: '可上课程',
                    dataIndex: 'CourseCount',
                },
                {
                    title: '课程费用总额',
                    dataIndex: 'xxxxxx2',
                },
                {
                    title: '折扣/附加金额',
                    dataIndex: 'xxxxx',
                },
                {
                    title: '应付总金额',
                    dataIndex: 'PayAmount',
                },
                {
                    title: '课程实际单价',
                    dataIndex: 'price',
                },
                {
                    title: '交费状态',
                    dataIndex: 'PayState',
                },
                {
                    title: '签单人',
                    dataIndex: 'SignUser',
                },
                {
                    title: '创建人',
                    dataIndex: 'CreateUser',
                },
                {
                    title: '创建时间',
                    dataIndex: 'CreateTime',
                },
                {
                    title: '所属中心',
                    dataIndex: 'center',
                },
                {
                    title:"合同状态",
                    dataIndex: "state"
                }
            ],

            tableData:[
                {
                    ContractNo:"Ct12345678"
                }
            ],




        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        this.clientH = domHeight - 227 + 'px'
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({

            })
        },
        searchBelongChange(event){

        },
        searchStateChange(event){

        },
        searchSubmit(){

        },

        handleTableChange(){

        },


        pageChange(){

        },

        contractDrawerOpen(row){
            console.log(row)
            this.showContractDetail=true
        },
        contractDrawClose(){
            this.showContractDetail=false
        },
        contractDrawTabTap(){

        },


        contractFreezingFormCancel(){
            this.showContractFreezingForm=false;
        },
        contractFreezingSubmit(){

        },
        handleContrctOperateClick({key}){
            console.log(key)
            switch (key) {
                case "AddCommissionUserShow" :
                    this.showAddCommissionUserForm=true
                    break
                case "IssueInvoice" :
                    this.openModalByKey("showIssueInvoiceForm")
                    break
                case "DeleteContract" :
                    this.openModalByKey("showDeleteContractForm")
                    break
                case "ExtendedDate" :
                    this.openModalByKey("showExtendedDateForm")
                    break
                case "SendContractWp" :
                    this.openModalByKey("showSendContractWpForm")
                    break
                case "FeeRefund" :
                    this.showContractDetail=false
                    this.$router.push({ path: '/customContactFeeRefund', query: { contractNo: this.operationContractItem.ContractNo }})
                    break
                default:
                    break
            }
        },


        addCommissionUserFormCancel(){
            this.showAddCommissionUserForm=false
        },
        commissionUserCenterChange(){

        },



        issueInvoiceSubmit(){

            //
            this.closeModalByKey('showIssueInvoiceForm')
        },


        deleteContractSubmit(){
            //
            this.closeModalByKey('showDeleteContractForm')
        },


        extendedDateSubmit(){
            //
            this.closeModalByKey('showExtendedDateForm')
        },


        sendContractWpMsg(item,index){

        },

        updateLessonSubmit(){

        },


        closeModalByKey(key) {
            this.showModalObject[key]=false
        },

        openModalByKey(key) {

            this.showModalObject[key]=true
            return true
        }

    }
}
</script>
<style scoped lang="less">
.contract-manage-list-page {
    margin: 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;



    .pagination-row{
        text-align: right;
    }


}


.contract-detail-drawer{
    .contract-detail-head-row {
        .contract-head-col-icon {
            width: 64px;
            height: 64px;
            margin-right: 10px;
            img {
                width: 64px;
                height: 64px;
            }
        }
        .contract-drawer-btn-group{
            margin-top: 20px;
            .contract-drawer-group-btn{
                margin-left: 10px;
            }
        }
    }
    .contract-detail-brief-row{
        display: flex;
        justify-items: center;
        justify-content: space-around;
        .contract-detail-brief-col{
            text-align: center;
        }
    }
    .contract-detail-tab-menu{
        width: 100%;
    }
}

</style>
