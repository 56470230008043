<template>
	<div class="contract-trans-page">
        <div>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <a slot="ContractNo" style="color:  #108ee9" slot-scope="row" @click="contractDrawerOpen(row)">{{ row }}111</a>
            </a-table>
        </div>
	</div>
</template>
<script>
	export default {
		name: 'ContractOptionLog',
		data() {
			return {
                loading:false,
                columns : [
                    {
                        title: '交易凭证',
                        dataIndex: 'ContractNo',
                        sorter: true,
                        width: 'auto',
                        scopedSlots: { customRender: 'ContractNo' },
                    },
                    {
                        title: '学员',
                        dataIndex: 'StudentName',
                        width: 'auto',
                    },
                    {
                        title: '交易类型',
                        dataIndex: 'TransType',
                    },
                    {
                        title: '交易方式',
                        dataIndex: 'PayType',
                    },
                    {
                        title: '交易金额',
                        dataIndex: 'amount',
                    },
                    {
                        title: '交易时间',
                        dataIndex: 'TransTime',
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'CenterName',
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateName',
                    },
                    {
                        title: '出纳',
                        dataIndex: 'TellerName',
                    },

                ],
                tableData:[
                    {
                        ContractNo:"Ct12345678"
                    }
                ],
            }
		},
		created() {
		},
		mounted() {

		},
		methods: {
            commissionInfoShow(){
                this.commissionInfoVisible=true;
            },
            handleTableChange(){

            }
		}
	}
</script>
<style scoped lang="less">
	.contract-trans-page {
        padding-top: 50px;
        padding-left: 50px;
        margin-bottom: 120px;
	}



</style>
